import { object as dotObject } from 'dot-object';
import $ from 'jquery';
import { SubmissionError, change } from 'redux-form';

import { BasePureComponent } from 'components/Base';
import * as errors from 'util/errors';
import './styles.scss';

/**
 * A base form which all other forms should extend.
 */
export class BaseForm extends BasePureComponent {
  componentDidMount() {
    // parent, for lifecycle logging
    super.componentDidMount();

    // are we doing address auto-completion?
    if (this.addressAutoComplete()) {
      this.loadGoogleMaps()
        .then((maps) => {
          // get the field
          const address1 = $(`input[name="${this.addressAutoComplete().keyField}"]`).get(0);

          // if we have an input element, set up the auto-complete
          if (address1) {
            const autocomplete = new maps.places.Autocomplete(address1, {
              types: ['address'], // limit to complete addresses
            });

            // we only need the address components
            autocomplete.setFields(['address_components']);

            // bind a listener to place selection
            autocomplete.addListener('place_changed', () => {
              // get the selected place
              const place = autocomplete.getPlace();
              if (place && place.address_components) {
                // extract the address components
                const address = {};
                const components = place.address_components;
                for (let i = 0; i < components.length; i++) {
                  const component = components[i];
                  if (component.types.includes('street_number')) {
                    if (!address.street) {
                      address.street = component.long_name;
                    } else {
                      address.street = `${component.long_name} ${address.street}`;
                    }
                  } else if (component.types.includes('route')) {
                    if (!address.street) {
                      address.street = component.long_name;
                    } else {
                      address.street = `${address.street} ${component.long_name}`;
                    }
                  } else if (component.types.includes('locality')) {
                    address.city = component.long_name;
                  } else if (component.types.includes('administrative_area_level_1')) {
                    address.state = component.short_name;
                  } else if (component.types.includes('postal_code')) {
                    address.zip = component.long_name;
                  }
                }

                // update the form fields
                this.props.dispatch(
                  change(
                    this.props.form,
                    this.addressAutoComplete().addressFields.street,
                    address.street,
                  ),
                );
                if (this.addressAutoComplete().addressFields.street2) {
                  // Google doesn't provide this
                  this.props.dispatch(
                    change(this.props.form, this.addressAutoComplete().addressFields.street2, ''),
                  );
                }
                this.props.dispatch(
                  change(
                    this.props.form,
                    this.addressAutoComplete().addressFields.city,
                    address.city,
                  ),
                );
                this.props.dispatch(
                  change(
                    this.props.form,
                    this.addressAutoComplete().addressFields.state,
                    address.state,
                  ),
                );
                this.props.dispatch(
                  change(
                    this.props.form,
                    this.addressAutoComplete().addressFields.zip,
                    address.zip,
                  ),
                );
              }
            });
          }
        })
        .catch((error) => {
          console.error('Error loading Google Maps API; autocomplete will be disabled', error);
        });
    }
  }

  componentWillUnmount() {
    // parent, for lifecycle logging
    super.componentWillUnmount();

    // if we have an auto-complete component, get rid of it
    if (this.addressAutoComplete() && this.addressAutoComplete().keyField) {
      const field = $(`input[name="${this.addressAutoComplete().keyField}"]`);
      if (field && field.get(0)) {
        window.google.maps.event.clearInstanceListeners(field);
        $('.pac-container').remove();
      }
    }
  }

  // load Google Maps API if it's not already loaded
  loadGoogleMaps = () => {
    return new Promise((resolve, reject) => {
      // check if the script is already loaded
      const existingScript = document.getElementById('googleMaps');
      if (!existingScript) {
        // if not, load it
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GMAPS_KEY}&libraries=places`;
        script.id = 'googleMaps';
        document.body.appendChild(script);

        // success
        script.onload = () => {
          // make sure it loaded successfully; something like a quota break or invalid API key
          // will not be exposed by simply loading the script, we have to make a call
          const testPlace = 'ChIJIcCPSmSiVogRM1_9fPDcVBI';
          if (window.google.maps.places) {
            new window.google.maps.places.PlacesService(document.createElement('div')).getDetails(
              { placeId: testPlace, fields: ['place_id'] },
              () => {
                // works
                resolve(window.google.maps);
              },
            );
          } else {
            // didn't load properly
            reject('Google Maps API did not load properly');
          }
        };

        // error
        script.onerror = (error) => {
          console.error('Error loading Google Maps API', error);
          reject(error);
        };
      } else {
        // if it's already loaded, we're done
        resolve(window.google.maps);
      }
    });
  };

  // do not do address auto-completion by default
  addressAutoComplete = () => undefined;

  renderFacilityOptions(facilities, idIsValue = true, placeholderText = undefined) {
    // sort them by name
    facilities.sort((a, b) => {
      return a.name > b.name;
    });

    // build the options
    let items = [];
    items.push(
      <option key={0} value={''} disabled>
        {placeholderText || ''}
      </option>,
    );
    let used = [];
    for (var i = 0; i < facilities.length; i++) {
      let value = facilities[i].netParkCode;
      let name = facilities[i].name;
      if (idIsValue) {
        value = facilities[i].id;
      } else {
        // if we are using netPark codes as values, eliminate duplicates
        if (used.includes(value)) {
          continue;
        } else {
          used.push(value);
          if (name.lastIndexOf(': ') > 0) {
            name = name.substring(0, name.lastIndexOf(': '));
          }
        }
      }
      items.push(
        <option key={facilities[i].id} value={value}>
          {name}
        </option>,
      );
    }
    return items;
  }

  renderStateOptions() {
    let items = [];
    items.push(<option key="" />);
    items.push(
      <option key="AL" value="AL">
        Alabama
      </option>,
    );
    items.push(
      <option key="AK" value="AK">
        Alaska
      </option>,
    );
    items.push(
      <option key="AZ" value="AZ">
        Arizona
      </option>,
    );
    items.push(
      <option key="AR" value="AR">
        Arkansas
      </option>,
    );
    items.push(
      <option key="CA" value="CA">
        California
      </option>,
    );
    items.push(
      <option key="CO" value="CO">
        Colorado
      </option>,
    );
    items.push(
      <option key="CT" value="CT">
        Connecticut
      </option>,
    );
    items.push(
      <option key="DE" value="DE">
        Delaware
      </option>,
    );
    items.push(
      <option key="DC" value="DC">
        District Of Columbia
      </option>,
    );
    items.push(
      <option key="FL" value="FL">
        Florida
      </option>,
    );
    items.push(
      <option key="GA" value="GA">
        Georgia
      </option>,
    );
    items.push(
      <option key="HI" value="HI">
        Hawaii
      </option>,
    );
    items.push(
      <option key="ID" value="ID">
        Idaho
      </option>,
    );
    items.push(
      <option key="IL" value="IL">
        Illinois
      </option>,
    );
    items.push(
      <option key="IN" value="IN">
        Indiana
      </option>,
    );
    items.push(
      <option key="IA" value="IA">
        Iowa
      </option>,
    );
    items.push(
      <option key="KS" value="KS">
        Kansas
      </option>,
    );
    items.push(
      <option key="KY" value="KY">
        Kentucky
      </option>,
    );
    items.push(
      <option key="LA" value="LA">
        Louisiana
      </option>,
    );
    items.push(
      <option key="ME" value="ME">
        Maine
      </option>,
    );
    items.push(
      <option key="MD" value="MD">
        Maryland
      </option>,
    );
    items.push(
      <option key="MA" value="MA">
        Massachusetts
      </option>,
    );
    items.push(
      <option key="MI" value="MI">
        Michigan
      </option>,
    );
    items.push(
      <option key="MN" value="MN">
        Minnesota
      </option>,
    );
    items.push(
      <option key="MS" value="MS">
        Mississippi
      </option>,
    );
    items.push(
      <option key="MO" value="MO">
        Missouri
      </option>,
    );
    items.push(
      <option key="MT" value="MT">
        Montana
      </option>,
    );
    items.push(
      <option key="NE" value="NE">
        Nebraska
      </option>,
    );
    items.push(
      <option key="NV" value="NV">
        Nevada
      </option>,
    );
    items.push(
      <option key="NH" value="NH">
        New Hampshire
      </option>,
    );
    items.push(
      <option key="NJ" value="NJ">
        New Jersey
      </option>,
    );
    items.push(
      <option key="NM" value="NM">
        New Mexico
      </option>,
    );
    items.push(
      <option key="NY" value="NY">
        New York
      </option>,
    );
    items.push(
      <option key="NC" value="NC">
        North Carolina
      </option>,
    );
    items.push(
      <option key="ND" value="ND">
        North Dakota
      </option>,
    );
    items.push(
      <option key="OH" value="OH">
        Ohio
      </option>,
    );
    items.push(
      <option key="OK" value="OK">
        Oklahoma
      </option>,
    );
    items.push(
      <option key="OR" value="OR">
        Oregon
      </option>,
    );
    items.push(
      <option key="PA" value="PA">
        Pennsylvania
      </option>,
    );
    items.push(
      <option key="RI" value="RI">
        Rhode Island
      </option>,
    );
    items.push(
      <option key="SC" value="SC">
        South Carolina
      </option>,
    );
    items.push(
      <option key="SD" value="SD">
        South Dakota
      </option>,
    );
    items.push(
      <option key="TN" value="TN">
        Tennessee
      </option>,
    );
    items.push(
      <option key="TX" value="TX">
        Texas
      </option>,
    );
    items.push(
      <option key="UT" value="UT">
        Utah
      </option>,
    );
    items.push(
      <option key="VT" value="VT">
        Vermont
      </option>,
    );
    items.push(
      <option key="VA" value="VA">
        Virginia
      </option>,
    );
    items.push(
      <option key="WA" value="WA">
        Washington
      </option>,
    );
    items.push(
      <option key="WV" value="WV">
        West Virginia
      </option>,
    );
    items.push(
      <option key="WI" value="WI">
        Wisconsin
      </option>,
    );
    items.push(
      <option key="WY" value="WY">
        Wyoming
      </option>,
    );
    items.push(
      <option key="AB" value="AB">
        Alberta
      </option>,
    );
    items.push(
      <option key="BC" value="BC">
        British Columbia
      </option>,
    );
    items.push(
      <option key="MB" value="MB">
        Manitoba
      </option>,
    );
    items.push(
      <option key="NB" value="NB">
        New Brunswick
      </option>,
    );
    items.push(
      <option key="NL" value="NL">
        Newfoundland and Labrador
      </option>,
    );
    items.push(
      <option key="NS" value="NS">
        Nova Scotia
      </option>,
    );
    items.push(
      <option key="NT" value="NT">
        Northwest Territories
      </option>,
    );
    items.push(
      <option key="NU" value="NU">
        Nunavut
      </option>,
    );
    items.push(
      <option key="ON" value="ON">
        Ontario
      </option>,
    );
    items.push(
      <option key="PE" value="PE">
        Prince Edward Island
      </option>,
    );
    items.push(
      <option key="QC" value="QC">
        Quebec
      </option>,
    );
    items.push(
      <option key="SK" value="SK">
        Saskatchewan
      </option>,
    );
    items.push(
      <option key="YT" value="YT">
        Yukon
      </option>,
    );
    return items;
  }
}

/**
 * A base edit form which all other edit forms should extend.
 */
export class BaseEditForm extends BaseForm {
  constructor(props) {
    // parent, for lifecycle logging
    super(props);

    // for edit management
    this.state = {
      ...this.state,
      currentlyEditing: null,
    };

    // make 'this' available in methods
    this.submit = this.submit.bind(this);
  }

  // clears tooltips and then invokes the onSubmit() callback passed in via props
  submit(_values, _dispatch, _props) {}
}

/**
 * Processes errors for UI display.
 *
 * This logic tightly integrates with redux-form's error display logic. If there are
 * errors, the result of this call is a SubmissionError thrown containing those errors.
 */
export function processErrors(e, dispatch = null, genericMessage = null, handler = null) {
  // log it
  console.debug('Processing errors for display', e);

  // we deal with arrays
  if (!Array.isArray(e)) {
    e = [e];
  }

  // to keep track of generic errors
  let genericError = false;

  // process all errors
  let processedErrors = {};
  e.forEach(function (error) {
    // if we have a handler defined, let that take a crack at it first
    if (handler) {
      const processed = handler(error, dispatch);
      if (processed) {
        processedErrors = {
          ...processedErrors,
          ...dotObject(processed),
        };

        // continue with next error
        return;
      }
    }

    // is it a field specific error?
    if (error.field) {
      // flag it as a field error
      processedErrors = {
        ...processedErrors,
        fieldError: true,
      };

      // we drive off of the code
      switch (error.code) {
        // we don't know this code
        default:
          processedErrors = {
            ...processedErrors,
            // typically we'd let the UI craft the messages (and localize them), but we were careful
            // to write customer-friendly messages in the Hub, so we'll use them directly
            ...dotObject({
              [error.field]: error.message,
            }),

            // we actually have to add it twice; the above notation takes care of the field-level
            //  message, and the following notation takes care of highlighting the invalid field
            [error.field]: error.message,
          };
          break;
      }

      // add a generic message too
      if (!genericError) {
        processedErrors = {
          ...processedErrors,
          _error:
            'Please correct the error' + (e.length !== 1 ? 's' : '') + ' below and try again.',
        };
        genericError = true;
      }
    } else {
      // looks like it's a non-field error; we still drive off of the code
      switch (error.code) {
        // this is a swagger error code that indicates a request payload problem
        case 601:
          // note the error
          if (!genericError) {
            processedErrors = {
              ...processedErrors,
              _error:
                "The mothership (our server) isn't happy with what we're telling her. Sorry about that! Please wait a few minutes and then try again.",
            };
            genericError = true;
          }
          break;

        // this is a swagger error code; let's try to figure out which field is in error
        case 602:
          if (
            error.message &&
            (error.message.endsWith(' in body is required') ||
              error.message.endsWith(' in query is required'))
          ) {
            // extract the field and build a new error using it
            const field = error.message
              .replace(' in body is required', '')
              .replace(' in query is required', '');
            processedErrors = {
              ...processedErrors,
              ...dotObject({
                [field]: 'This field is required',
              }),
            };

            // flag it as a field error
            processedErrors = {
              ...processedErrors,
              fieldError: true,
            };

            // add a generic message too
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  'Please correct the error' +
                  (e.length !== 1 ? 's' : '') +
                  ' below and try again.',
              };
              genericError = true;
            }
          } else {
            // we don't recognize it
            if (!genericError) {
              processedErrors = {
                ...processedErrors,
                _error:
                  "The mothership (our server) doesn't understand what we're saying. Sorry about that! Please wait a few minutes and then try again.",
              };
              genericError = true;
            }
          }
          break;

        // nothing we can do about this
        case errors.SERVER_ERROR:
          // note the error
          if (!genericError) {
            processedErrors = {
              ...processedErrors,
              _error:
                'Something went wrong on our end. Sorry about that! Please give it a minute and then try again.',
            };
            genericError = true;
          }
          break;

        // this may go away on a retry
        case errors.NETWORK_ERROR:
          // note the error
          if (!genericError) {
            processedErrors = {
              ...processedErrors,
              _error:
                'Oops, we had a problem talking to our server. Please check your connection and try again.',
            };
            genericError = true;
          }
          break;

        // this is probably a server misconfiguration
        case errors.UNAUTHORIZED:
          // note the error
          if (!genericError) {
            processedErrors = {
              ...processedErrors,
              _error:
                `Oops, we had a problem talking to our server. This is our fault, not yours. Trying again likely won't work. ` +
                `We've contacted our engineers with the details, and they'll get on it ASAP. Sorry about that!`,
            };
            genericError = true;
          }
          break;

        // challenge verification failure
        case 11000:
          processedErrors = {
            ...processedErrors,
            _error: `We had some trouble verifying that you aren't a robot. If you are not a robot, please try again. If you are a robot, please leave.`,
          };
          genericError = true;
          break;

        // catch-all
        default:
          if (!genericError) {
            processedErrors = {
              ...processedErrors,
              _error: genericMessage
                ? genericMessage
                : error.message
                ? error.message
                : 'Something is wrong here; please review and try again',
            };
            genericError = true;
          }
      }
    }
  });

  // if we have errors, scroll to the top of the page
  if (processedErrors._error) {
    window.scrollTo(0, 0);
  }

  // log and throw
  console.debug('Processed errors for display', processedErrors);
  throw new SubmissionError(processedErrors);
}
