import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { reset } from 'redux-form';

import { addAlert } from 'components/Alerts/actions';
import { hideBusy, showBusy } from 'components/Busy/actions';
import Content from 'components/Content';
import { go as navigate } from 'components/Navigate/actions';
import BasePage from 'components/Page';
import { send as sendEmail } from 'entities/Email/actions';
import CorporateInquiryForm from './components/CorporateInquiryForm/index';

/* Corporate inquiry page. */
class CorporateInquiry extends BasePage {
  render() {
    // parent, for lifecycle logging
    super.render();

    // render
    return (
      <div>
        <Content name="corporateInquiry" showError={true} />

        {/* the form */}
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <CorporateInquiryForm
                customer={this.props.customer}
                facilities={this.props.facilities}
                onSubmit={(values) => this.props.inquire(values)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// map state to properties relevant to this component
const mapStateToProps = (state, ownProps) => ({
  // the existing customer, if there is one
  customer: state.context.customer,

  // facilities
  facilities: state.context.facilities,
});

// map dispatch function to callback props so that the component can invoke them
const mapDispatchToProps = (dispatch, ownProps, getState) => ({
  // inquire
  inquire: (values) => {
    // show the busy indicator
    const busyId = dispatch(showBusy());

    // format the content
    const content = {
      content:
        `<p>\n` +
        `<strong>Contact:</strong><br/>` +
        `${values.firstName} ${values.lastName}<br/>` +
        (values.title ? `${values.title}<br/>` : ``) +
        `${values.email}<br/>` +
        `${values.workPhone}<br/>` +
        `</p>\n` +
        `<p>\n` +
        `<strong>Organization:</strong><br/>` +
        `${values.organization.name}<br/>` +
        (values.organization.website
          ? `<a href="${values.organization.website}">${values.organization.website}</a><br/>`
          : ``) +
        `${values.organization.address.street}<br/>` +
        (values.organization.address.street2 ? `${values.organization.address.street2}<br/>` : ``) +
        `${values.organization.address.city}, ${values.organization.address.state} ${values.organization.address.zip}<br/>` +
        `</p>\n` +
        `<p>\n` +
        `<strong>Other:</strong><br/>` +
        `${values.travelers} Travelers<br/>` +
        `Primary Location Code: ${values.primaryLocation}<br/>` +
        (values.referral ? `Referral: ${values.referral}<br/>` : ``) +
        `</p>\n`,
    };

    // do we have a challenge response?
    const challengeResponse = values.challengeResponse;

    // do the inquiry
    return dispatch(sendEmail('corporateInquiry', content, challengeResponse))
      .then(() => {
        // show a success message
        dispatch(addAlert('success', "Your inquiry has been sent. We'll be in touch!", 4000));

        // reset the form
        dispatch(reset('corporateInquiryForm'));

        // go home
        dispatch(navigate('home'));
      })
      .finally(() => {
        // hide the busy indicator
        dispatch(hideBusy(busyId));
      });
  },
});

// turn this into a container component
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CorporateInquiry));
